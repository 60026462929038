import { Box } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { Button } from "@molecules/Button";
import { CTA_HEIGHT, HEADER_HEIGHT } from "@screens/components/Header";
import { useRouting } from "expo-next-react-navigation";
import { Dimensions } from "react-native";
import { useTheme } from "styled-components/native";

export async function getStaticProps() {
    return {
        props: {
            layout: "static",
        },
    };
}

export default function Custom404() {
    const theme = useTheme();
    const { navigate } = useRouting();
    const { isCTAActive } = useAppContext();

    const onHome = () => {
        navigate({
            routeName: "/",
            web: {
                path: "/",
            },
        });
    };

    return (
        <Box
            style={{
                height:
                    Dimensions.get("window").height -
                    HEADER_HEIGHT -
                    (isCTAActive ? CTA_HEIGHT : 0),
            }}
            align="center"
            justify="center"
            gap={theme.spacing.biggest}
        >
            <Text style={{ fontSize: 100 }} bold>
                404
            </Text>
            <Text big semiBold>
                {`Oops! That page can't be found.`}
            </Text>
            <Text
                small
                style={{ lineHeight: theme.text.biggest }}
                textAlign="center"
                color={theme.color.rgba(theme.color.white, 0.7)}
            >
                {`It looks like the page you're looking for may have been moved, deleted, or doesn't exist.\nPlease check the URL for any mistakes, or go back to the homepage and try again.`}
            </Text>
            <Button text="Go to homepage" primary onPress={onHome} />
        </Box>
    );
}
